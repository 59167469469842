import Modal from 'react-modal';
import dynamic from 'next/dynamic';
const Icon = dynamic(() => import('../Icon/Icon'))
import { SpotlightContentVideoConstants, ImageConstants, VideoPlayerConstants, ContentConstants } from '../../adapters/helpers/Constants';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import HomeVideoPlayer from '../HomeVideoPlayer/HomeVideoPlayer'
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export default function VideoBackgroundWrapper({
    backgroundAsset, mainAssetOffsetTop, mainAssetOffsetRight, mainAssetOffsetBottom, mainAssetOffsetLeft,
    playLabel, pauseLabel, resetLabel,
    openTranscriptLabel, closeTranscriptLabel, children
}) {

    const videoPlayerRef = useRef(null);
    let [windowWidth, setWindowWidth] = useState(ImageConstants.defaultWidth);
    const [playing, setIsPlaying] = useState(false);
    const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
    const [currentVideoRef, setCurrentVideoRef] = useState(null);
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 200
        },
        content: {
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '45px 20px 20px',
            fontSize: '1.4rem',
            lineHeight: '2.1rem',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '123.6rem',
            width: '90%'
        }
    };

    const handleResetClick = useCallback(() => {
        const videoRef = videoPlayerRef.current?.getVideoRef();
        const videoPlayerControlsRef = videoPlayerRef?.current?.getVideoPlayerRef();
        if (videoPlayerControlsRef) {
            videoPlayerControlsRef.currentTime(0);
            videoPlayerControlsRef.play();
        } else if (videoRef) {
            videoRef.currentTime = 0;
            setIsPlaying(true)
            videoRef.play();
        }
    }, []);

    const handleVideoEvent = useCallback((value) => {
        setIsPlaying(value);
    }, []);

    const handlePlayClick = useCallback(() => {
        const videoRef = videoPlayerRef.current?.getVideoRef();
        const videoPlayerControlsRef = videoPlayerRef?.current?.getVideoPlayerRef();

        if (videoPlayerControlsRef && videoRef && videoRef instanceof HTMLVideoElement) {
            if (playing) {
                videoPlayerControlsRef.pause();
            } else {
                videoPlayerControlsRef.play();
            }
        } else if (videoRef && videoRef instanceof HTMLVideoElement) {
            if (playing) {
                videoRef.pause();
                setIsPlaying(false)
            } else {
                videoRef.play();
                setIsPlaying(true)
            }
        }
    }, [playing]);

    const handleTranscriptOpen = useCallback(() => {
        setIsTranscriptModalOpen(true);
    }, []);

    const handleTranscriptClose = useCallback(() => {
        setIsTranscriptModalOpen(false);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        return (() => {
            window.removeEventListener('resize', handleResize)
        })

    }, [])

    function handleResize() {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        const videoRef = videoPlayerRef.current?.getVideoRef();
        const video = videoPlayerRef.current?.getVideo();
        if (videoRef && videoRef instanceof HTMLVideoElement) {
            setCurrentVideoRef(video);
        }
    }, [videoPlayerRef.current]);

    const videoPositioningStyles = {
        top: mainAssetOffsetTop,
        right: SpotlightContentVideoConstants.defaultRight,
        bottom: SpotlightContentVideoConstants.defaultBottom,
        left: mainAssetOffsetLeft,
    };

    if (windowWidth) {
        videoPositioningStyles.right = mainAssetOffsetRight
        videoPositioningStyles.bottom = mainAssetOffsetBottom
    }

    return (
        <div className={'ob-contentBlock-video-wrapper'}>
            <div className='ob-contentBlock-video' style={videoPositioningStyles}>

                {backgroundAsset && backgroundAsset.fields.isHomeVideoPlayerNeeded ? <HomeVideoPlayer ref={videoPlayerRef} video={backgroundAsset.fields} onVideoPlaying={handleVideoEvent} /> :
                    <VideoPlayer ref={videoPlayerRef} video={backgroundAsset.fields} onVideoPlaying={handleVideoEvent} />}

            </div>
            <div className={'ob-contentBlock-video-controls-wrapper'}>
                <div className={'ob-contentBlock-video-controls'}>
                    <button onClick={handlePlayClick}
                        aria-label={playing ? ContentConstants.pause : ContentConstants.play}
                        style={{
                            color: ['white'],
                            outlineColor: ['white'],
                            height: '2.3rem',
                            position: 'absolute',
                            bottom: '0.5rem',
                            left: '2.3rem',
                            zIndex: '10'
                        }}
                        className="event_video_play ob-video-player-button vjs-play-control"
                        data-action-detail={playing ? SpotlightContentVideoConstants.paused : SpotlightContentVideoConstants.played}>
                        {playing ?
                            (
                                <Icon name={SpotlightContentVideoConstants.spotlightPause}
                                    color={'white'}
                                    size={'1.4'} />

                            ) : (
                                <Icon name={SpotlightContentVideoConstants.spotlightPlay}
                                    color={'white'}
                                    size={'1.6'} />
                            )
                        }
                    </button>

                    <button onClick={handleResetClick}
                        aria-label={ContentConstants.replay}
                        className="ob-video-player-button ob-video-reset-button"
                        style={{
                            color: ['white'],
                            outlineColor: 'white',
                            position: 'absolute',
                            height: '2.3rem',
                            bottom: '0.5rem',
                            left: '4.8rem',
                            zIndex: '10'
                        }}>
                        <Icon name={SpotlightContentVideoConstants.spotlightReplay}
                            color={'white'}
                            size={'1.7'} />
                    </button>

                    {currentVideoRef && currentVideoRef.transcript &&
                        <button onClick={handleTranscriptOpen}
                            aria-label={openTranscriptLabel}
                            className="ob-video-player-button ob-video-show-transcript"
                            style={{
                                color: ['#ffffff'],
                                outlineColor: ['#ffffff'],
                                position: 'absolute',
                                height: '2.3rem',
                                bottom: '0.4rem',
                                left: '7.8rem',
                                zIndex: '10'
                            }}>
                            <Icon name={SpotlightContentVideoConstants.spotlightTranscript}
                                color={'#ffffff'}
                                size={'1.3'} />
                        </button>
                    }

                    {currentVideoRef && currentVideoRef.transcript &&
                        <Modal
                            isOpen={isTranscriptModalOpen}
                            onRequestClose={handleTranscriptClose}
                            closeTimeoutMS={250}
                            style={customModalStyles}>
                            <button className="ob-modal-close no-shadow"
                                onClick={handleTranscriptClose}
                                aria-label={closeTranscriptLabel}>
                                <Icon name={SpotlightContentVideoConstants.close} size="2" color="#0057B8" />
                            </button>
                            {currentVideoRef.transcript}
                        </Modal>
                    }
                </div>
            </div>

            <div className='ob-contentBlock-video-content'>
                {children}
            </div>
        </div>
    )
}

VideoBackgroundWrapper.propTypes = {
    backgroundAsset: PropTypes.any,
    children: PropTypes.node,
    mainAssetOffsetTop: PropTypes.string,
    mainAssetOffsetRight: PropTypes.string,
    mainAssetOffsetBottom: PropTypes.string,
    mainAssetOffsetLeft: PropTypes.string,
    playLabel: PropTypes.string,
    pauseLabel: PropTypes.string,
    resetLabel: PropTypes.string,
    openTranscriptLabel: PropTypes.string,
    closeTranscriptLabel: PropTypes.string,
};

VideoBackgroundWrapper.defaultProps = {
    backgroundAsset: undefined,
    children: null,
    mainAssetOffsetTop: '0',
    mainAssetOffsetRight: '0',
    mainAssetOffsetBottom: '0',
    mainAssetOffsetLeft: '0',
    playLabel: null,
    pauseLabel: null,
    resetLabel: null,
    openTranscriptLabel: null,
    closeTranscriptLabel: null,
};
